import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';

export interface IAccountUsageReportData {
  accountId: string;
  accountName: string;
  totalDevices: number;
  totalDevicesWereOnline: number;
  hoursOnline: number;
  callsMade: number;
  callsConnected: number;
  messagesSent: number;
}

export type tAccountUsageReportData = {
  accountId: string;
  accountName: string;
  totalDevices: number;
  totalDevicesWereOnline: number;
  hoursOnline: number;
  callsMade: number;
  callsConnected: number;
  messagesSent: number;
};

export const getAccountsUsageReportData = async (year: number, month: number): Promise<IAccountUsageReportData[] | any> => {
  try {
    const response = await httpNodeApi.get(`report/accounts-usage-report?year=${year}&month=${month}`);
    console.log("getAccountsUsageReportData =>", response);
    if (response?.data) return { data: response?.data };
    return null;
  } catch (error) {
    console.log('getAccountsUsageReportData Error =>', error);
    return error;
  }
};
