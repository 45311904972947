import React, { useMemo, useState, useEffect, useRef } from 'react';
import NucleusTable from '../../components/NucleusTable';
import NucleusButtonV2 from '../../components/NucleusButtonV2';
import { SpinnerCircular } from 'spinners-react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { getAccountsUsageReportData } from './ReportsApi';
import Message from '../../utils/Message';
import ParamSelect from './AccountsUsageReportParamsSelector';

const monthName= {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
}
const generateMonthsData = () => {
  const months = [
    { value: 1, name: "January" },
    { value: 2, name: "February" },
    { value: 3, name: "March" },
    { value: 4, name: "April" },
    { value: 5, name: "May" },
    { value: 6, name: "June" },
    { value: 7, name: "July" },
    { value: 8, name: "August" },
    { value: 9, name: "September" },
    { value: 10, name: "October" },
    { value: 11, name: "November" },
    { value: 12, name: "December" },
  ];
  const result = {};
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); 
  const currentYear = currentDate.getFullYear();
  for (let year = currentYear; year >= 2024; year--) {
    const yearData = [];
    const endMonth = year === currentYear ? currentMonth - 1  : 11;
    for (let i = 0; i <= endMonth; i++) {
      yearData.push(months[i]);
    }
    result[year] = yearData;
  }
  return result;
};

const generateYearsArray = (data) => {
  return Object.keys(data).map(year => ({
    value: parseInt(year, 10),
    name: year 
  }));
};

const TimeData = generateMonthsData();

const AccountsUsageReport = () => {
  const [reportData, setReportData] = React.useState([]);

  const [stateLoading, setStateLoading] = useState(true);
  const [stateYear, setStateYear] = useState(null);
  const [stateMonth, setStateMonth] = useState(null);
  const [stateMonthName, setStateMonthName] = useState(null);
  const yearsSelectOptions =  generateYearsArray(TimeData) || [];
  const monthsSelectOptions = useMemo(() => {
    return TimeData[stateYear] || [];
  }, [stateYear]);
  
  const [stateExportFileName, setStateExportFileName] = useState('AccountsFullUsageReport_');

  useEffect(() => {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthName = currentDate.toLocaleString('en-US', { month: 'long' });
    setStateYear(year);
    setStateMonth(month);
    setStateMonthName(monthName);
    refreshReport(year, month);
  }, []);

  const refreshReport = async (year, month) => {
    console.log("refreshReport", year, month);
    setStateLoading(true);
    const response = await getAccountsUsageReportData(year, month);
    console.log('getAccountsUsageReportData response ->', response);
    setStateLoading(false);
    if (response?.data) {
      setReportData(response?.data);
    } else {
      Message.warning('Error loading report, please try again.');
      setReportData([]);
    }
    let exportCSVFileName = 'AccountsFullUsageReport_' + stateMonthName + '_' + stateYear + '.csv';
    setStateExportFileName(exportCSVFileName);
  };

  const tableData = useMemo(() => {
    return reportData.map((row) => {
      return {
        ...row,
        HoursOnline: row.Hours,
        TotalDevicesWereOnline: row.WereOnline,
      };
    });
  }, [reportData]);

  const columns = useMemo(() => {
    const returnCols = [
      {
        Header: 'Account',
        accessor: 'accountName',
        key: 'accountName',
      },
      {
        Header: 'Number of Devices',
        accessor: 'totalDevices',
        key: 'totalDevices',
      },
      {
        Header: 'Devices Online',
        accessor: 'totalDevicesWereOnline',
        key: 'totalDevicesWereOnline',
      },
      {
        Header: 'Hours Online',
        accessor: 'hoursOnline',
        key: 'hoursOnline',
      },
      {
        Header: 'Calls Made',
        accessor: 'callsMade',
        key: 'callsMade',
      },
      {
        Header: 'Calls Connected',
        accessor: 'callsConnected',
        key: 'callsConnected',
      },
      {
        Header: 'Messages Sent',
        accessor: 'messagesSent',
        key: 'messagesSent',
      },
    ];
    return returnCols;
  }, [tableData]);

  const appsUsageReportRef = useRef([]);
  appsUsageReportRef.current = tableData;

  const csvFileHeadersRef = useRef([]);
  csvFileHeadersRef.current = [
    { label: 'Account', key: 'accountName' },
    { label: 'Number of Devices', key: 'totalDevices' },
    { label: 'Devices Online', key: 'totalDevicesWereOnline' },
    { label: 'Hours Online', key: 'hoursOnline' },
    { label: 'Calls Made', key: 'callsMade' },
    { label: 'Calls Connected', key: 'callsConnected' },
    { label: 'Messages Sent', key: 'messagesSent' },
  ];

  const handleYearChange = (e) => {
    console.log("handleYearChange", e.target.value);
    setStateYear(Number(e.target.value));
    const selectedYearMonths = TimeData[Number(e.target.value)];
    const selectedYearMaxMonthValue = Math.max(...selectedYearMonths.map(month => month.value));
    if (stateMonth > selectedYearMaxMonthValue){
      Message.show("There is no data for " + monthName[stateMonth] + " " + e.target.value);
      handleMonthChange(selectedYearMaxMonthValue);
    }
  };

  const handleMonthChange = (monthNumber) => {
    console.log("handleMonthChange", monthNumber);
    const month = monthsSelectOptions.find(month => month.value === monthNumber);
    if (month?.name){
      setStateMonth(month.value);
      setStateMonthName(month.name);
    }
  };

  return (
    <div className="nucleus-tools-container">
      <div>
        <p className="nucleus-tools-page-title">
          <b>Reports</b>
        </p>
      </div>
      <ReportContainer>
        <div>
          <p style={{ color: '#0A313F', fontSize: 20 }}>
            <b>Full Accounts Usage</b>
          </p>
        </div>
        <ReportControllsContainer>
          <div>
            <ReportDateControllsTitle>Year</ReportDateControllsTitle>
            <ParamSelect
              id='selectYear'
              placeholder='Year'
              selectedValue={stateYear}
              options={yearsSelectOptions}
              handleSelectChange={handleYearChange}
              style={{width:90}}
            >
            </ParamSelect>
          </div>
          <ReportControllsSeparator></ReportControllsSeparator>
          <div>
            <ReportDateControllsTitle>Month</ReportDateControllsTitle>
            <ParamSelect
              id='selectMonth'
              placeholder='Month'
              selectedValue={stateMonth}
              options={monthsSelectOptions}
              handleSelectChange={(e)=>{handleMonthChange(Number(e.target.value))}}
              style={{width:136}}
            >
            </ParamSelect>
          </div>
          <div style={{ marginLeft: 55 }}>
            <NucleusButtonV2 onClick={() => refreshReport(stateYear, stateMonth)} styleType="outline">
              Apply
            </NucleusButtonV2>
          </div>
        </ReportControllsContainer>
        <div style={{ height: '55vh', overflowY: 'scroll', borderRadius: 10 }}>
          {stateLoading ? (
            <LoadingView>
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness="100" />
            </LoadingView>
          ) : (
            <NucleusTable
              stickyThead
              tableStyles={{
                backgroundColor: 'white',
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 10,
              }}
              columns={columns}
              data={tableData}
            />
          )}
        </div>
      </ReportContainer>
      <ReportFooterButtons>
        {tableData && tableData.length > 0 && (
          <CSVLink 
            headers={csvFileHeadersRef.current} 
            data={tableData} 
            filename={stateExportFileName} target="_blank"
          >
            <NucleusButtonV2 styleType="outline">Export to CSV</NucleusButtonV2>
          </CSVLink>
        )}
      </ReportFooterButtons>
    </div>
  );
};

export default AccountsUsageReport;

const ReportContainer = styled.div`
  background-color: #ebf2fe;
  min-height: 75vh;
  padding: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  //border-bottom: 2px solid #B4C6D3;
`;

const ReportControllsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 20px;
`;

const ReportDateControllsTitle = styled.span`
  margin-left: 4px;
  margin-right: 28px;
  color: #0092ff;
`;

const ReportControllsSeparator = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin: 0px 10px 0px;
`;

const LoadingView = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReportFooterButtons = styled.div`
  background-color: #ebf2fe;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: flex-end;
  display: flex;
`;
